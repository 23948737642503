<template>
  <div class="post-view" v-if="currentBlog">
    <div class="container quillWrapper">
        <h2>{{this.currentBlog[0].blogTitle}}</h2>
        <h4>Posted on: {{new Date(this.currentBlog[0].blogDate).toLocaleString('en-us', {dateStyle: "long"})}}</h4>
        <img :src="this.currentBlog[0].blogCoverPhoto" alt="">
        <div class="post-content ql-editor" v-html="this.currentBlog[0].blogHTML"></div>
        <br>
        <br>
        <h3>Comentarios</h3>
        <br>
        <div v-if="this.profileId != null">
            <input type="textarea" name="nuevoComentario" id="nuevoComentario" style="width:300px;height:250px;border:none;display:block;padding:4px;" placeholder="Escribir Comentario..."
            v-model="comentarioNuevo">
            <br>
            <button @click="comentar" style="display:block;">Comentar</button>
        </div>
        <br>
        <br>
        <div>
            <p v-for="comentario in this.currentBlog[0].comentarios" :key="comentario.id">  
                {{comentario.usuario}}: {{comentario.comentario}}
            </p>
        </div>
    </div>
  </div>
</template>

<script>
import db from "../firebase/firebaseInit";

export default {
    name: "ViewBlog",
    data(){
        return {
            routeID: null,
            currentBlog: null,
            comentarioNuevo: ''

        }
    },
    methods:{
        async comentar(){
            const dataBase = await db.collection('blogPosts').doc(this.routeID);
            let conNuevo = this.currentBlog[0].comentarios
            const nombre = this.$store.state.profileFirstName +' '+ this.$store.state.profileLastName
            conNuevo.push(
                {
                    usuario: nombre,
                    comentario: this.comentarioNuevo,
                    id: Date.now()
                }
            )
            await dataBase.update({
                comentarios: conNuevo,
            })
        }
    },
    async mounted(){
        this.routeID = this.$route.params.blogid;
        this.currentBlog = await this.$store.state.blogPosts.filter(post => {
            return post.blogID ===this.routeID;
        })
        this.$store.commit('setBlogState', this.currentBlog[0]);
        console.log(this.routeID)
    },
    computed: {
        profileId (){
            return this.$store.state.profileId;
        },
    }
}
</script>

<style lang="scss">
.post-view{
    h4{
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 24px;
    }
}

</style>