<template>
  <div class="container">
    <div class="blog-info">
      <input type="text" placeholder="Busca por titulo, ingredientes, etc..." v-model="busqueda">
      <button class="preview" @click="buscarTag">Buscar</button>
    </div>
    <div class="blog-card-wrap">
      <div class="blog-cards container">
            <BlogCard v-for="(post, index) in blogPosts" :key="index" :post="post"/>
      </div>
  </div>
  </div>
</template>

<script>
import BlogCard from "../components/BlogCard.vue";

export default {
    name: "Busqueda",
      data(){
        return{
          busqueda: '',
          blogPosts: null
        }
    },
    components: {BlogCard},
    methods:{
      async buscarTag(){
        console.log(this.busqueda)
        this.blogPosts = await this.$store.state.blogPosts.filter(post => {
            return post.tags.includes(this.busqueda.toLowerCase()) || post.blogTitle.includes(this.busqueda.toLowerCase());
        })
      }
    },
    computed: {
/*         blogPosts() {
          return this.$store.state.blogPosts;
        } */
    }
}
</script>

<style>
  .container {
    position: relative;
    height: 100%;
    padding: 10px 25px 60px;
  }
  .blog-info {
    display: flex;
    margin-bottom: 32px;
    input:nth-child(1) {
      min-width: 300px;
    }
    input {
      transition: 0.5s ease-in-out all;
      padding: 10px 2px;
      border: none;
      border-bottom: 1px solid #303030;
      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #303030;
      }
    }
    .upload-file {
      flex: 1;
      margin-left: 16px;
      position: relative;
      display: flex;
      input {
        display: none;
      }
      .preview {
        margin-left: 16px;
        text-transform: initial;
      }
      span {
        font-size: 12px;
        margin-left: 16px;
        align-self: center;
      }
    }
  }
.blog-cards {
  position: relative;
  .toggle-edit {
    display: flex;
    align-items: center;
    position: absolute;
    top: -70px;
    right: 0;
    span {
      margin-right: 16px;
    }
    input[type="checkbox"] {
      position: relative;
      border: none;
      -webkit-appearance: none;
      background: #fff;
      outline: none;
      width: 80px;
      height: 30px;
      border-radius: 20px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input[type="checkbox"]:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background: #303030;
      transform: scale(1.1);
      transition: 750ms ease all;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input:checked[type="checkbox"]:before {
      background: #fff;
      left: 52px;
    }
  }
}

</style>