<template>
  <div class="create-post">
    <Loading v-show="loading"/>
    <BlogCoverPreview v-show="this.$store.state.blogPhotoPreview"/>
    <div class="container">
        <div :class="{'invisible': !error}" class="err-message">
            <p><span>Error: </span>{{this.errorMsg}}</p>
        </div>
        <div class="blog-info">
            <input type="text" placeholder="Titulo de la receta..." v-model="blogTitle">
            <input type="text" placeholder="Tags (Separadas por coma)" v-model="tags">
            <div class="upload-file">
                <label for="blog-photo">Upload Cover Photo</label>
                <input type="file" id="blog-photo" ref="blogPhoto" accept=".png, .jpg, .jpeg" @change="fileChange">
                <button class="preview" :class="{'button-inactive': !this.$store.state.blogPhotoFileURL}" @click="openPreview">Preview Photo</button>
                <span>File Chosen: {{this.$store.state.blogPhotoName}}</span>
            </div>
        </div>
        <div class="editor">
            <vue-editor :editorOptions="editorSettings" v-model="blogHTML" useCustomImageHandler @image-added="imageHandler"/>
        </div>
        <div class="blog-actions">
            <button @click="uploadBlog">Publish Blog</button>
            <router-link :to="{name: 'BlogPreview'}" class="router-button">Post Preview</router-link>
        </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/storage";
import db from "../firebase/firebaseInit";

import Quill from "quill";
window.Quill = Quill;
const ImageResize = require("quill-image-resize-module").default;
Quill.register("modules/imageResize", ImageResize);

import BlogCoverPreview from "../components/BlogCoverPreview";
import Loading from '../components/Loading.vue';

export default {
    name: "CreatePost",
    data(){
        return{
            error: null,
            errorMsg: null,
            editorSettings: {
                modules:{
                    imageResize: {

                    }
                }
            },
            file: null,
            loading: null,
            tags: ''
        }
    },
    components:{
        BlogCoverPreview,
        Loading,
    },
    methods:{
      fileChange(){
        this.file = this.$refs.blogPhoto.files[0];
        const fileName = this.file.name;
        this.$store.commit("fileNameChange", fileName);
        this.$store.commit("createFileURL", URL.createObjectURL(this.file));
      },
      openPreview(){
        this.$store.commit("openPhotoPreview");
      },
      imageHandler(file, Editor, cursorLocation, resetUploader){
        const storageRef = firebase.storage().ref();
        const docRef = storageRef.child(`documents/blogPostPhotos/${file.name}`);
        docRef.put(file).on(
          "state_changed", 
          (snapshot) => {
            console.log(snapshot);
          }, (error) => {
            console.log(error);
          }, async () => {
            const downloadURL = await docRef.getDownloadURL();
            Editor.insertEmbed(cursorLocation, "image", downloadURL);
            resetUploader();
          }
        );
      },
      uploadBlog(){
        if(this.blogTitle.length !== 0 && this.blogHTML !== 0){
          if(this.file){
            this.loading = true;
            const storageRef = firebase.storage().ref();
            const docRef = storageRef.child(`documents/BlogCoverPhotos/${this.$store.state.blogPhotoName}`)
            docRef.put(this.file).on(
              "state_changed", 
              (snapshot) => {
                console.log(snapshot);
              }, (error) => {
                console.log(error);
                this.loading = false;
              }, async () => {
                const downloadURL = await docRef.getDownloadURL();
                const timestamp = await Date.now();
                const dataBase = await db.collection("blogPosts").doc();

                await dataBase.set({
                  blogID: dataBase.id,
                  blogHTML: this.blogHTML,
                  blogCoverPhoto: downloadURL,
                  blogPhotoName: this.blogPhotoName,
                  blogTitle: this.blogTitle,
                  profileId: this.profileId,
                  date: timestamp,
                  tags: this.tags
                });
                await this.$store.dispatch("getPost");
                this.loading = false;
                this.$router.push({name: "ViewBlog", params: {blogid: dataBase.id}})
              }
            );
            return;
          }
          this.error = true;
          this.loading = false;
          this.errorMsg = "Please ensure you upload a Blog Cover Photo!"
          setTimeout(()=>{
            this.error = false;
          },5000)
        }
        else{
          this.error = true;
          this.loading = false;
          this.errorMsg = "Please ensure Blog Title & Post has been filled!"
          setTimeout(()=>{
            this.error = false;
          },5000)
        }
      }
    },
    computed: {
        profileId (){
            return this.$store.state.profileId;
        },
        blogPhotoName(){
            return this.$store.state.blogPhotoName;
        },
        blogTitle: {
            get(){
                return this.$store.state.blogTitle;
            },
            set(payload){
                this.$store.commit("updateBlogTitle", payload)
            }
        },
        blogHTML: {
            get(){
                return this.$store.state.blogHTML;
            },
            set(payload){
                this.$store.commit("newBlogPost", payload)
            }
        }
    }
}
</script>

<style lang="scss">
.create-post {
  position: relative;
  height: 100%;
  button {
    margin-top: 0;
  }
  .router-button {
    text-decoration: none;
    color: #fff;
  }
  label,
  button,
  .router-button {
    transition: 0.5s ease-in-out all;
    align-self: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 20px;
    padding: 12px 24px;
    color: #fff;
    background-color: #303030;
    text-decoration: none;
    &:hover {
      background-color: rgba(48, 48, 48, 0.7);
    }
  }
  .container {
    position: relative;
    height: 100%;
    padding: 10px 25px 60px;
  }
  // error styling
  .invisible {
    opacity: 0 !important;
  }
  .err-message {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    color: #fff;
    margin-bottom: 10px;
    background-color: #303030;
    opacity: 1;
    transition: 0.5s ease all;
    p {
      font-size: 14px;
    }
    span {
      font-weight: 600;
    }
  }
  .blog-info {
    display: flex;
    margin-bottom: 32px;
    input:nth-child(1) {
      min-width: 300px;
    }
    input {
      transition: 0.5s ease-in-out all;
      padding: 10px 4px;
      border: none;
      border-bottom: 1px solid #303030;
      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #303030;
      }
      margin-right: 10px;
    }
    .upload-file {
      flex: 1;
      margin-left: 16px;
      position: relative;
      display: flex;
      input {
        display: none;
      }
      .preview {
        margin-left: 16px;
        text-transform: initial;
      }
      span {
        font-size: 12px;
        margin-left: 16px;
        align-self: center;
      }
    }
  }
  .editor {
    height: 60vh;
    display: flex;
    flex-direction: column;
    .quillWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .ql-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll;
    }
    .ql-editor {
      padding: 20px 16px 30px;
    }
  }
  .blog-actions {
    margin-top: 32px;
    button {
      margin-right: 16px;
    }
  }
}
</style>