<template>
  <div class="form-wrap">
      <form class="register">
          <p class="login-register">
              Ya tienes cuenta?
              <router-link class="router-link" :to="{name: 'Login'}">Login</router-link>
          </p>
          <h2>Crea tu cuenta en FoodiePub</h2>
          <div class="inputs">
              <div class="input">
                  <input type="text" placeholder="Nombre" v-model="firstName">
                  <user class="icon" />
              </div>
              <div class="input">
                  <input type="text" placeholder="Apellidos" v-model="lastName">
                  <user class="icon" />
              </div>
              <div class="input">
                  <input type="text" placeholder="Usuario" v-model="username">
                  <user class="icon" />
              </div>
              <div class="input">
                  <input type="text" placeholder="Correo" v-model="email">
                  <email class="icon" />
              </div>
              <div class="input">
                  <input type="password" placeholder="Contraseña" v-model="password">
                  <password class="icon" />
              </div>
              <div class="error" v-show="error">
                  {{this.errorMsg}}
              </div>
          </div>
          <button @click.prevent="register">Registrate</button>
          <div class="angle"></div>
      </form>
      <div class="background"></div>
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg"
import password from "../assets/Icons/lock-alt-solid.svg"
import user from "../assets/Icons/user-alt-light.svg"

import firebase from "firebase/app";
import "firebase/auth";
import db from "../firebase/firebaseInit";


export default {
    name: "Register",
    data(){
        return{
            email: "",
            password: "",
            username: "",
            firstName: "",
            lastName: "",
            error: null,
            errorMsg: "",
        }
    },
    components: {
        email, password, user
    },
    methods: {
        async register() {
            if(this.email !== "" && this.password !== "" && this.firstName !== "" && this.lastName !== "" && this.username !== ""){
                console.log("valdio");
                this.error = false;
                this.errorMsg = "";
                const firebaseAuth = await firebase.auth();
                const createUser = await firebaseAuth.createUserWithEmailAndPassword(this.email, this.password);
                const result = await createUser;
                const dataBase = db.collection("users").doc(result.user.uid);
                await dataBase.set({
                    firstName: this.firstName,
                    lastName: this.lastName,
                    userName: this.username,
                    email: this.email
                });
                this.$router.push({name: "Home"});
                return;
            }
            this.error = true;
            this.errorMsg = "Please fill out all the fields!";
            return;
        },
    }

}
</script>

<style lang="scss" scoped>
.register{
    h2{
        max-width: 350px;
    }
}
</style>