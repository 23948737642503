import firebase from "firebase/app"
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB-CDf_NGOLbCuLFz3fzmHrbb00yFngSe4",
    authDomain: "fireblogs-80790.firebaseapp.com",
    projectId: "fireblogs-80790",
    storageBucket: "fireblogs-80790.appspot.com",
    messagingSenderId: "233881799059",
    appId: "1:233881799059:web:c2470c3297dfaef4a24066"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {timestamp};
export default firebaseApp.firestore();